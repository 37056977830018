import React, { useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './Home.css';
import ImageSlideIn from '../ImageSlideIn/ImageSlideIn';

export default function Home() {
  const arrowRef = useRef(null);
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;
      if (window.scrollY > scrollThreshold) {
        arrowRef.current.style.opacity = '0';
      } else {
        arrowRef.current.style.opacity = '1';
      }

      // Check if any image is in viewport
      imageRefs.current.forEach((imageRef) => {
        if (imageRef) {
          const rect = imageRef.getBoundingClientRect();
          const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
          if (isVisible) {
            imageRef.classList.add('slide-in');
          } else {
            imageRef.classList.remove('slide-in');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="Home" className="tabcontent">
      <Container fluid className="video-container">
        <video playsInline autoPlay loop muted>
          <source src="/img/home-vid.MP4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-logo-container">
          <img src="/img/stackpackers-logo-yellow.svg" alt="logo" />
        </div>
      </Container>
      <div ref={arrowRef} className="scroll-down-arrow">
        <span></span>
      </div>
      <div className="about text-center">
        <p>Stackpackers (Sara and Joe), born in the UK and now based in Naarm/ Melbourne. 
          Known for their electrifying performances and distinctive sound, Stackpackers 
          have built a reputation for performing captivating sets that showcase their unique 
          blends and high-energy selections. The duo mix and produce drum & bass, alongside a 
          diverse range of music that encompasses genres such as house, psytrance, and dubstep.</p>

        <p>Sara and Joe met in Tabulam, NSW, during their 88 days of regional work for their Working 
          Holiday Visas. The pair quickly bonded over their love of Drum & Bass and travelling. Upon 
          returning to Melbourne, they immersed themselves in the local music scene. It wasn't long 
          before Stackpackers formed, marking the inception of their musical partnership.</p>
        </div>
      <div className="image-gallery">
        <ImageSlideIn ref={(el) => (imageRefs.current[0] = el)} src="/img/touchbass.jpg" alt="Stackpackers at Touch Bass / Twisted Audio" />
        <p className="photo-credit">Photo by <a href="https://www.instagram.com/larkzvisuals_events/" target="_blank" rel="noopener noreferrer">Larkz Visuals</a></p>
      </div>
    </div>
  );
}
